import "./index.scss";
import 'antd/dist/antd.css'
import React, { useEffect, useState } from "react";
import {
  Input,
  Space,
  Button,
  Row,
  Col,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Form,
  notification,
  List,
  Card,
  Modal
} from "antd";
import { FaSearch } from "react-icons/fa";
import API_SERVICE from "shared/services/api-service";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import debounce from "lodash/debounce";
import { hasUserRole, isUserVerified } from "shared/services/Utility";
import type { ColumnsType } from "antd/es/table";
import iconPlay from "../../assets/images/play.svg";
import { ReactComponent as IconShare } from "../../assets/images/shareIcon.svg";
import { ReactComponent as IconPlayNewBorder } from "../../assets/images/play_new_border.svg";
import { ReactComponent as IconEditBorder } from "../../assets/images/edit_border.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { setActiveKey, setIsShowFilter, setLeadPageNo, setLeadPageSize, setLeadSearchParam, setPostLenderCase, setPreLenderCase, setRestFilterData } from "shared/redux/lead-reducer";
import LeadFilter from "./LeadFilter/LeadFilter";
import { getUserDetails } from "shared/services/Utility";
import dayjs from "dayjs";
import { setActiveTab, setIsAppFooterShow, setIsAppHeaderShow, setShowProfielVerification } from "shared/redux/common-reducer";
import blackFilterIcon from "../../assets/images/blackFilter.svg";
import whatsappIcon from "../../assets/images/whatsappIcon.svg";
import blackRefereshIcon from "../../assets/images/blackReferesh.svg";
import { setScreenHeader } from "shared/redux/commonScreen-reducer";

const { CheckableTag } = Tag;
const { TabPane } = Tabs;

type Props = {};

const Lead: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();
  const history = useHistory();
  const location = useLocation() as any;
  const [allLeadsData, setAllLeadsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [counts, setCounts] = useState({ totalCases: 0, totalCasesInLeadState: 0, totalCasesInProspectState: 0, totalCasesInVisitState: 0, totalCasesInDocumentState: 0, totalLenderAssignedCases: 0, totalLenderLoggedInCases: 0, totalLenderSanctionedCases: 0, totalLenderRejectedCases: 0, totalLenderWithdrawnCases: 0, totalLenderPendencyCases: 0, totalLenderDisbursedCases: 0, totalInsurerPolicyIssued: 0 ,totalInsurerPolicyUnderwritingReject:0,totalInsurerPolicyCounterOffer:0});
  const [form] = Form.useForm();
  const lead: any = useSelector((state: RootState) => state.lead);
  const dispatch = useDispatch();
  const [isModalWhatsappVisible, setIsModalWhatsappVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState<any>(null);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isWhatsappEnable, setIsWhatsappEnable] = useState(false);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);
  const { appColors, isMobile } = useSelector((state: RootState) => state.common);

  const preLenderColumns: ColumnsType<any> = [
    {
      title: "Customer Name",
      dataIndex: ["contactDetails", "firstName"],
      key: "name",
      width: 50,
      ellipsis: true
    },
    {
      title: "Product Type",
      dataIndex: ["loanType"],
      key: "productType",
      ellipsis: true,
    },
    {
      title: "Version",
      width: 50,
      dataIndex: "productVersion",
      key: "version",
      align: "center"
    },
    {
      title: "Stage",
      dataIndex: "caseStatus",
      key: "stage",
    },
    {
      title: "Employee Name",
      dataIndex: ["leadOriginatorDetails", "userDetails", "firstName"],
      key: "employeeName",
      ellipsis: true,
    },
    {
      title: "Mobile Number",
      dataIndex: ["contactDetails", "mobileNumber"],
      key: "mobileNumber",
      render: (_: any, record: any) =>
        record?.contactDetails?.mobileNumber.startsWith("1")
          ? ""
          : record?.contactDetails?.mobileNumber,
    },
    {
      title: "Case Created Date",
      dataIndex: "caseCreatedDate",
      key: "caseCreatedDate",
      width: 180,
      render: (_: any, record: any) =>
        moment(record?.caseCreatedDate)
          .locale("en")
          .format("MMM, DD, YYYY HH:mm"),
    },
    {
      title: "Days Open",
      dataIndex: "daysOpen",
      key: "daysOpen",
      width: 30,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 60,
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          <Button
            // className="blue"
            className="custome-btn-icon-secondary"
            style={{ '--bgColor': appColors?.appSecondaryColor ?? "" } as any}
            size="small"
            // type="primary"
            // shape="circle"
            hidden={"LEAD" === lead.preLenderCase && "PRE_LENDER" === lead.activeKey}
            onClick={() => getShareLinkId(record)}
            icon={<IconShare />}
          />
          {isWhatsappEnable &&
            <Button
              size="small"
              hidden={!record?.isWhatsAppEnabled}
              onClick={() => {
                setSelectedLead(record);
                handleClick(record?.caseId);
              }}
              icon={<img src={whatsappIcon} alt="" height={24} style={{ marginTop: '-4px' }} />}
            />
          }
          <Button
            className="custome-btn-icon-play"
            style={{ '--bgColor': appColors?.appPrimaryColor ?? "" } as any}
            size="small"
            // type="primary"
            // shape="circle"
            hidden={"LEAD" === lead.preLenderCase && "PRE_LENDER" === lead.activeKey}
            onClick={() => goToLeadDetails(record)}
            icon={<IconPlayNewBorder />}
          />
          <Button
            className="custome-btn-icon-secondary"
            style={{ '--bgColor': appColors?.appSecondaryColor ?? "" } as any}
            size="small"
            type="primary"
            // shape="circle"
            disabled={userRolePermission["ReadOnly"]}
            hidden={
              JSON.parse(localStorage.getItem("basicDetails") ?? "{}")?.srmId !== getUserDetails()?.userId
            }
            onClick={() => goToLeadEdit(record)}
            icon={<IconEditBorder />}
          />
        </Space>
      ),
    },
  ]

  const columns: ColumnsType<any> = [
    {
      title: "Customer Name",
      dataIndex: ["contactDetails", "firstName"],
      key: "name",
      width: 50,
      ellipsis: true
    },
    {
      title: "Product Type",
      dataIndex: ["loanType"],
      key: "productType",
      ellipsis: true,
    },
    {
      title: "Version",
      width: 50,
      dataIndex: "productVersion",
      key: "version",
      align: "center"
    },
    {
      title: "Stage",
      dataIndex: ["caseDetails", "caseStatus"],
      key: "stage",
    },
    {
      title: "Employee Name",
      dataIndex: ["caseDetails", "connectorDetails", "userDetails", "firstName"],
      key: "employeeName",
      ellipsis: true,
    },

    {
      title: "Mobile Number",
      dataIndex: ["contactDetails", "mobileNumber"],
      key: "stage",
      render: (_: any, record: any) =>
      (
        record?.contactDetails?.mobileNumber.startsWith("1") ? "" : record?.contactDetails?.mobileNumber
      )
    },
    {
      title: "Case Created Date",
      dataIndex: "caseCreatedDate",
      key: "caseCreatedDate",
      width: 180,
      render: (_: any, record: any) =>
        moment(record.caseCreatedDate)
          .locale("en")
          .format("MMM, DD, YYYY HH:mm"),
    },
    {
      title: "Days Open",
      dataIndex: "daysOpen",
      key: "daysOpen",
      width: 30,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 60,
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          {tenantInfo?.businessProductType !== "INSURANCE" &&
            <Button
              className="custome-btn-icon-secondary"
              style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
              size="small"
              hidden={"LEAD" === lead.preLenderCase && "PRE_LENDER" === lead.activeKey}
              onClick={() => getShareLinkId(record)}
              icon={<IconShare/>}
            />
          }
          {isWhatsappEnable &&
            <Button
              size="small"
              hidden={!record?.isWhatsAppEnabled}
              onClick={() => {
                setSelectedLead(record);
                handleClick(record.caseDetails.caseId);
              }}
              icon={<img src={whatsappIcon} alt="" height={24} style={{ marginTop: '-4px' }} />}
            />
          }
          <Button
            className="custome-btn-icon-play"
            style={{ '--bgColor': appColors?.appPrimaryColor ?? "" } as any}
            size="small"
            // type="primary"
            // shape="circle"
            hidden={"LEAD" === lead.preLenderCase && "PRE_LENDER" === lead.activeKey}
            onClick={() => goToLeadDetails(record)}
            icon={<IconPlayNewBorder />}
          />
          <Button
            className="custome-btn-icon-secondary"
            style={{ '--bgColor': appColors?.appSecondaryColor ?? "" } as any}
            size="small"
            type="primary"
            // shape="circle"
            disabled={userRolePermission["ReadOnly"]}
            hidden={
              JSON.parse(localStorage.getItem("basicDetails") ?? "{}")?.srmId !== getUserDetails()?.userId
            }
            onClick={() => goToLeadEdit(record)}
            icon={<IconEditBorder />}
          />
          {/* <Button
            className="pink"
            size="small"
            type="primary"
            shape="circle"
            icon={<AiFillDelete />}
          /> */}
        </Space>
      ),
    },
  ];

 

  const preLenderCases = tenantInfo?.businessProductType === "INSURANCE"
  ? [
      {
        key: "ALL",
        value: "All"
      },
      {
        key: "LEAD",
        value: "Lead",
      },
      {
        key: "PROSPECT",
        value: "Prospect",
      },
      {
        key: "DOCUMENTATION",
        value: "Documentation"
      }
    ]
  :  [
    {
      key: "ALL",
      value: "All"
    },
    {
      key: "LEAD",
      value: "Lead  "
    },
    {
      key: "PROSPECT",
      value: "Prospect "
    },
    {
      key: "VISIT",
      value: "Visit  "
    },
    {
      key: "DOCUMENTATION",
      value: "Documentation  "
    }
  ];

  const postLenderCases =  tenantInfo?.businessProductType === "INSURANCE"
  ? [
      {
        key: "LOGGED",
        value: "Log in",
      },
      {
        key: "POLICY_ISSUED",
        value: "Policy Issued",
      },
      {
        key: "WITHDRAWN",
        value: "Withdrawn"
      },
      {
        key:"UNDERWRITING_REJECT",
        value:"Underwriting Reject",
      },
      {
        key:"COUNTER_OFFER",
        value: "Counter Offer",
      },
     
    ]
  : [
    {
      value: "Lender Selection",
      key: "ASSIGNED",
    },
    {
      value: "Log in",
      key: "LOGGED",
    },
    {
      key: "SANCTIONED",
      value: "Sanctioned",
    },
    {
      key: "REJECTED",
      value: "Rejected",
    },
    {
      key: "WITHDRAWN",
      value: "Withdrawn"
    },
    {
      key: "PENDENCY",
      value: "Pendency"
    },
    {
      key: "DISBURSED",
      value: "Disbursed"
    },
  ];

  if (location?.state) {
    location.state = null;
  }

  const userVerified = () => {
    if (!isUserVerified()) {
      dispatch(setShowProfielVerification(true))
      notification.error({ message: "Please verify your profile to proceed" });
      return false
    }
    return true
  }
  /* useEffect functions */
  useEffect(() => {
    dispatch(setScreenHeader({ screenTitle: "Manage Leads" }))
    dispatch(setActiveTab(1));
    dispatch(setIsAppFooterShow(true));
    dispatch(setIsAppHeaderShow(true));

    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredProspectOnboardingStatus = enumValues.find(
        (item: any) => item.propertyName === "PROSPECT_ONBOARDING_STATUS"
      );

    }
  }, []);


  useEffect(() => {
    form.setFieldsValue({
      searchBox: lead.searchParam
    })

    getAllLeadsData(lead.pageNo, lead.pageSize, lead.searchParam, lead.filterData);
  }, [lead.activeKey, lead.searchParam, lead.preLenderCase, lead.postLenderCase, lead.filterData]);

  /* other functions */
  const refreshLead = () => {
    //dispatch(setFilterData({}));
    dispatch(setRestFilterData());
    dispatch(setActiveKey("PRE_LENDER"));
    dispatch(setPreLenderCase("ALL"));
    if (tenantInfo?.businessProductType === "INSURANCE") {
      dispatch(setPostLenderCase("LOGGED"));
    } else {
      dispatch(setPostLenderCase("ASSIGNED"));
    }
    //setSelectedFilterData({});
    dispatch(setIsShowFilter(false));
    form.setFieldsValue({
      searchBox: ""
    });
    dispatch(setLeadSearchParam(""));
    dispatch(setLeadPageNo(1));
    // getAllLeadsData(1, lead.pageSize, "", {});
  }

  const goToLeadEdit = (item: any) => {
    if (userVerified()) {
      history.push(`/lead/edit/${item?.caseDetails?.caseId}`);
    }
  };

  const goToLeadDetails = (item: any) => {
    let caseId = lead?.activeKey == "PRE_LENDER" ? item?.caseId : item?.caseDetails?.caseId;
    if (userVerified()) {
        history.push(`/lead/details/${caseId}`);
    }
  };

  const handleClick = (caseId) => {
    API_SERVICE.getWhatsAppDocumentValidate(caseId)
      .then(({ data }) => {
        if (data.payload.validToSend) {
          setIsModalWhatsappVisible(true);
        }
        else {
          setIsModalWhatsappVisible(false);
          setSuccessMessage(data.payload.message);
          setIsSuccessModalVisible(true);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  }



  const handleModalOk = () => {
    const caseId = lead?.activeKey == "PRE_LENDER" ? selectedLead?.caseId : selectedLead.caseDetails.caseId;
    API_SERVICE.sendWhatsAppMessage(caseId)
      .then(({ data }) => {
        setIsModalWhatsappVisible(false);
        if (data.payload) {
          notification.success({ message: data.payload })
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const handleModalCancel = () => {
    setIsModalWhatsappVisible(false);
  };
  const handleModalmessage = () => {
    setIsSuccessModalVisible(false)
  }
  const whatsapp = () => {
    return (
      <Modal
        centered
        width={450}
        visible={isModalWhatsappVisible}
        footer={null}
        closable={false}
      >
        <div
          style={{
            paddingBottom: "40px",
            paddingTop: "40px",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "35px",
              marginLeft: "5px",
              fontSize: "medium",
            }}
          >
            Is {selectedLead?.contactDetails?.mobileNumber} the valid contact number? <br />
            if not, please update lead details.
          </p>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <Button
              type="primary"
              className="dynamic-btn-primary-default"
              style={{ '--bgColor': appColors?.appPrimaryColor ?? "", width: "155px", height: "40px", borderRadius: "8px" } as any}
              onClick={handleModalOk}
            >
              Confirm
            </Button>
            <Button
              type="default"
              className="dynamic-btn-default"
              style={{ '--borderColor': appColors?.appSecondaryColor ?? "", width: "155px", height: "40px", borderRadius: "8px" } as any}
              onClick={handleModalCancel}
            >
              Back
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const message = () => {
    return (<Modal
      centered
      width={450}
      visible={isSuccessModalVisible}

      footer={null}
      closable={false}
    > <p
      style={{
        textAlign: "center",
        marginTop: "30px",
        marginBottom: "15px",
        marginLeft: "5px",
        fontSize: "medium",
      }}
    >{successMessage}
      </p>
      <Button
        type="default"
        className="dynamic-btn-default"
        style={{ '--borderColor': appColors?.appSecondaryColor ?? "", width: "155px", height: "40px", borderRadius: "8px", marginLeft: "123px" } as any}
        onClick={handleModalmessage}
      >
        OK
      </Button>

    </Modal>
    )
  }

  const getShareLinkId = (item: any) => {
    let caseId = lead.activeKey === "PRE_LENDER" ? item?.caseId : item?.caseDetails?.caseId
    API_SERVICE.getShareLinkId(caseId)
      .then(({ data }) => {
        navigator.clipboard.writeText(data?.payload)
      }).catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      })
  }

  const resetCounts = () => {
    setCounts(counts => ({
      ...counts,
      totalCases: 0,
      totalCasesInLeadState: 0,
      totalCasesInProspectState: 0,
      totalCasesInVisitState: 0,
      totalCasesInDocumentState: 0,
      totalLenderAssignedCases: 0,
      totalLenderLoggedInCases: 0,
      totalLenderSanctionedCases: 0,
      totalLenderRejectedCases: 0,
      totalLenderWithdrawnCases: 0,
      totalLenderDisbursedCases: 0
    }));
  }

  const getAllLeadsData = (
    pageNo: number = 1,
    pageSize: number = 10,
    searchValue: any = null,
    //caseStatus: string = lead.preLenderCase,
    filter: any = lead.filterData) => {
    let params = {};
    if (lead.activeKey === "PRE_LENDER" && (lead.preLenderCase === "VISIT" || lead.preLenderCase === "LEAD" || lead.preLenderCase === "DOCUMENTATION" || lead.preLenderCase === "PROSPECT")) {
      params = {
        ...filter,
        searchParam: searchValue,
        status: lead.preLenderCase,
      };
    } else {
      params = {
        ...filter,
        searchParam: searchValue,
      };
    }

    if (lead.activeKey === "PRE_LENDER")
      getPreLenderData(pageNo, pageSize, params);
    else if (lead.activeKey === "POST_LENDER")
      getPostLenderData(lead.postLenderCase, pageNo, pageSize, params);
  }


  const getPreLenderData = (pageNo, pageSize, params) => {
    setLoading(true);
    API_SERVICE.getLeadsByFilter(pageNo, pageSize,"LIST_LEADS", params)
      .then(({ data }) => {
        mapLeadsData(data);
      }).catch((error) => {
        mapLeadsData([]);
        notification.error({ message: API_SERVICE.handleErrors(error) });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getPostLenderData = (caseStatus, pageNo, pageSize, params) => {
    setLoading(true);
    API_SERVICE.getLeadsByFilterPostCase(caseStatus, pageNo, pageSize,"LIST_LEADS", params)
      .then(({ data }) => {
        mapLeadsData(data);
      }).catch((error) => {
        mapLeadsData([]);
        notification.error({ message: API_SERVICE.handleErrors(error) });
      }).finally(() => {
        setLoading(false);
      });
  }

  const mapLeadsData = (data: any) => {
    //setCheckedListFilter(data?.payload);
    resetCounts();
    setCounts(counts => ({
      ...counts,
      totalCases: data?.payload?.totalCases ?? 0,
      totalCasesInLeadState: data?.payload?.totalCasesInLeadState ?? 0,
      totalCasesInProspectState: data?.payload?.totalCasesInProspectState ?? 0,
      totalCasesInVisitState: data?.payload?.totalCasesInVisitState ?? 0,
      totalCasesInDocumentState: data?.payload?.totalCasesInDocumentState ?? 0,
      totalLenderAssignedCases: data?.payload?.totalLenderAssignedCases ?? 0,
      totalLenderLoggedInCases: data?.payload?.totalLenderLoggedInCases ?? 0,
      totalLenderSanctionedCases: data?.payload?.totalLenderSanctionedCases ?? 0,
      totalLenderRejectedCases: data?.payload?.totalLenderRejectedCases ?? 0,
      totalLenderWithdrawnCases: data?.payload?.totalLenderWithdrawnCases ?? 0,
      totalLenderPendencyCases: data?.payload?.totalLenderPendencyCases ?? 0,
      totalLenderDisbursedCases: data?.payload?.totalLenderDisbursedCases ?? 0,
      totalInsurerPolicyIssued: data?.payload?.totalInsurerPolicyIssued ?? 0,
      totalInsurerPolicyUnderwritingReject:data?.payload?.totalInsurerPolicyUnderwritingReject??0,
      totalInsurerPolicyCounterOffer:data?.payload?.totalInsurerPolicyCounterOffer??0,
    }));
    setTotalRecords(data?.payload?.totalElements);

    setAllLeadsData(data?.payload?.content);
    setIsWhatsappEnable(data?.payload?.whatsappEnabled)
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setLeadPageNo(current))
    dispatch(setLeadPageSize(pageSize))
    getAllLeadsData(current, pageSize, lead.searchParam);
    window.scrollTo(0, 0);
  };

  const handlePreLenderTagChange = (i: number, value: string) => {
    dispatch(setLeadPageNo(1));
    dispatch(setPreLenderCase(value));
  };
  const handlePostLenderTagChange = (i: number, value: string) => {
    dispatch(setLeadPageNo(1));
    dispatch(setPostLenderCase(value));
  };

  const activeTabChange = (key) => {
    dispatch(setLeadPageNo(1));
    dispatch(setActiveKey(key))
  }
  const handleAddLead = () => {
    if (userVerified()) {
      history.push("/lead/add-lead");
    }
  }

  const customPrimary: any = { '--bgColor': appColors?.appPrimaryColor ?? ""};

  const renderTabs = () => {
    return (
      <Tabs activeKey={lead.activeKey} onChange={(key) => activeTabChange(key)} style={{marginBottom:"20px"}}>
        <TabPane tab={tenantInfo?.businessProductType === "INSURANCE"
              ? "Pre Insurer"
              : "Pre Lender"} key="PRE_LENDER">
          {preLenderCases.map((item: any, i: number) => (
            <CheckableTag
              className="custom-checkable"
              key={item.key}
              style={customPrimary}
              checked={item.key === lead.preLenderCase}
              onChange={(checked) => handlePreLenderTagChange(i, item.key)}
            >
              {item.value}
            </CheckableTag>
          ))}
        </TabPane>
        <TabPane tab={tenantInfo?.businessProductType === "INSURANCE"
              ? "Post Insurer"
              : "Post Lender"} key="POST_LENDER">
          {postLenderCases.map((item: any, i: number) => (
            <CheckableTag
              className="custom-checkable"
              key={item.key}
              style={customPrimary}
              checked={item.key === lead.postLenderCase}
              onChange={(checked) => handlePostLenderTagChange(i, item.key)}
            >
              {item.value}
            </CheckableTag>
          ))}
        </TabPane>
      </Tabs>
    );
  };

  const renderLeadListItem = (item: any) => {
    return (
      <List.Item onClick={() => goToLeadDetails(item)}>
        <Card>
          <Row gutter={24}>
            <Col span={12}>
              <label className="leadlist-title">Lead</label>
              <br />
              {item.contactDetails.firstName}
            </Col>
            <Col span={12} className="text-right">
              {item.loanType}
            </Col>
          </Row>
          <Row className="title-space">
            <Col span={11}>
              <label className="leadlist-title"> Stage</label>
              <br />
              {item?.caseDetails?.caseStatus}
            </Col>
            <Col span={7}>
              <label className="leadlist-title">Created</label>
              <br />
              {dayjs(item?.caseDetails?.creationDate).format("DD  MMM  YY")}
            </Col>
            <Col span={6} className="text-right">
              <label className="leadlist-title">Days Open</label>
              <br />
              {item.daysOpen}
            </Col>
          </Row>
        </Card>
      </List.Item>
    );
  };

  return (
    <div className="content-box">
      {whatsapp()}
      {message()}
      
      <Form form={form}>
        <Row gutter={24} className="data-filter-bar">
          <Col xs={24} sm={16} md={12} lg={12} className="pd-lr-10">
            <Form.Item name="searchBox">
              <Input size="large" className="search-box" placeholder="Search" allowClear
                prefix={<FaSearch />}
                onChange={debounce((event) => {
                  if(event?.target?.value?.length > 2){
                    dispatch(setLeadSearchParam(event.target.value));
                    dispatch(setLeadPageNo(1));
                  }else if(event?.target?.value?.length == 0){
                    dispatch(setLeadSearchParam(""))
                    dispatch(setLeadPageNo(1));
                  }
                }, 600)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={12} lg={12} className="pd-lr-10">
            <Tooltip title="Filter" className="m-i-5">
              <Button
                type="primary"
                className="filter-btn"
                onClick={() => {
                  dispatch(setIsShowFilter(!lead.isShowFilter));
                }}
              >
                <img src={blackFilterIcon} alt="" />
                <div
                  className="badge-green"
                  hidden={
                    !(
                      lead.filterData?.connectorId ||
                      lead.filterData?.loanProductId ||
                      lead.filterData?.loanType ||
                      lead.filterData?.location ||
                      lead.filterData?.managerHierarchy
                    )
                  }
                />
              </Button>
            </Tooltip>
            <Tooltip title="Refresh" className="m-i-5">
              <Button
                type="primary"
                className="refresh-button"
                onClick={() => {
                  refreshLead();
                }}
              >
                <img
                  src={blackRefereshIcon}
                  alt=""
                  style={{ marginLeft: "-5px" }}
                />
              </Button>
            </Tooltip>
            <Button
              type="primary"
              className="add-btn m-i-5 dynamic-btn-primary-default"
              style={customPrimary}
              onClick={handleAddLead}
            >
              Add Lead
            </Button>
          </Col>
        </Row>
      </Form>

      {lead.isShowFilter && <LeadFilter key="NEW_LEAD_FILTER" />}

      {renderTabs()}

      {isMobile ? (
        <List
          style={{ width: "100%" }}
          grid={{ gutter: 24, column: 1, xs: 1, sm: 1, md: 1, lg: 1 }}
          pagination={{
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            current: lead.pageNo,
            pageSize: lead.pageSize,
            total: totalRecords,
          }}
          itemLayout="horizontal"
          dataSource={allLeadsData}
          renderItem={renderLeadListItem}
        />
      ) : (
        <Table
          className="dynamic-pagination-checkbox"
          style={{ "--bgColor": appColors?.appPrimaryColor ?? "" } as any}
          size="small"
          loading={loading}
          scroll={{ x: "max-content" }}
          dataSource={allLeadsData}
          columns={"PRE_LENDER" === lead.activeKey ? preLenderColumns : columns}
          pagination={{
            current: lead.pageNo,
            pageSize: lead.pageSize,
            total: totalRecords
          }}
          onChange={(pagination: any) => {
            handlePaginationChange(pagination.current, pagination.pageSize);
          }}
        />
      )}
    </div>
  );
};

export default Lead;
