import React, { useEffect, useState } from "react";
import "moment/locale/zh-cn";
import { PHONE_NUMBER_REGEX, EMPLOYEE_NAME_REGEX } from "shared/constants/AppConstants";
import { Typography, Row, Col, Form, notification } from "antd";
import API_SERVICE from "shared/services/api-service";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  getBasicDetails,
  getEnumList,
  getGenderKey,
  getUserDetails,
} from "shared/services/Utility";
import FormInput from "shared/components/FormInput/FormInput";
import FormSelect from "shared/components/FormSelect/FormSelect";
import RadioButton from "shared/components/RadioButton/RadioButton";
import FormButton from "shared/components/FormButton/FormButton";
import "./index.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Title } = Typography;

type Props = {};

const AddEmployee: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const userDetails = getUserDetails();
  const location = useLocation() as any;
  let { id } = useParams() as any;
  const basicDetails = getBasicDetails();
  const isConnectorEmployee =
    basicDetails?.connectorRole == "CONNECTOR_EMPLOYEE";
  const isShowAddEmployee =
    basicDetails?.connectorPermissions?.find(
      (permission) =>
        permission?.permissionKey == "SHOW_EDIT_ADD_EMPLOYEE_BUTTON"
    )?.permissionStatus ?? false;
  const isShowPayout =
    basicDetails?.connectorPermissions?.find(
      (permission) =>
        permission?.permissionKey == "SHOW_EDIT_PAYOUT_SCREEN_BUTTON"
    )?.permissionStatus ?? false;
  const { appColors } = useSelector((state: RootState) => state.common);
  const [designation, setDesignation] = useState([]);
  const [lineManagerList, setLineManagerList] = useState([]);
  const [connectorDetails, setConnectorDetails] = useState({} as any);
  const [employeeForm] = Form.useForm();
  const isViewScrren = location?.state?.type === "view";
  const isEditScreen = location?.state?.type === "edit";

  useEffect(() => {
    if (isEditScreen || isViewScrren) {
      getConnector();
    } else {
      if (isConnectorEmployee) {
        employeeForm.setFieldsValue({
          connectorRole: "CONNECTOR_EMPLOYEE",
        });
      }
    }

    getDesignation();

    employeeForm.setFieldsValue({
      viewPayoutScreen: "false",
      canAddEmployee: "false",
    });
  }, []);

  const setFormData = (data) => {
    employeeForm.setFieldsValue({
      canAddEmployee: `${data?.canAddEmployee ?? false}`,
      name: data?.name,
      phoneNumber: data?.phoneNumber,
      email: data?.emailId,
      empCode: data?.connectorAdditionalInfo?.empCode,
      gender: getGenderKey(data?.gender),
      connectorRole: data?.connectorRole,
      designation: data?.connectorAdditionalInfo?.designation,
      connectorManager: data?.connectorManagerId,
      viewPayoutScreen: "false",
    });
    // viewPayoutScreen: `${
    //   data?.connectorAdditionalInfo?.viewPayoutScreen ?? false
    // }`,
  };

  const getDesignation = () => {
    API_SERVICE.getActiveDesignation()
      .then(({ data }) => {
        setDesignation(data?.payload ?? []);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }).finally(() => {
        getLineManagers();
      });
  };

  const getLineManagers = () => {
    API_SERVICE.getLineManager()
      .then(({ data }) => {
        setLineManagerList(data?.payload ?? []);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getConnector = () => {
    if (userDetails !== null) {
      API_SERVICE.getConnectorById(id)
        .then(({ data }) => {
          setConnectorDetails(data?.payload?.basicDetails);
          setFormData(data?.payload?.basicDetails);
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  const buildPayload = (formData: any) => {
    console.log("formData: ", formData);

    const payload = {
      active: true,
      address: {},
      assignedTo: "",
      canAddEmployee: formData?.canAddEmployee ?? "false",
      comment: "",
      companyName: "",
      connectorAdditionalInfo: {
        designation: formData.designation,
        empCode: formData.empCode,
        viewPayoutScreen: formData?.viewPayoutScreen ?? "false",
      },
      connectorManagerId: formData?.connectorManager ?? null,
      connectorRoleKey: formData.connectorRole,
      driverLicenseNo: "",
      email: formData.email,
      gender: formData?.gender,
      managerId: "",
      name: formData.name.trim(),
      pan: "",
      phoneNumber: formData.phoneNumber,
      primaryRole: "",
      profession: "",
      referralId: "",
    };
    return payload;
  };

  const editEmployee = (values) => {
    const payload = {
      accounts: null,
      basicDetails: {
        ...connectorDetails,
        canAddEmployee: values?.canAddEmployee ?? "false",
        connectorManagerId: values?.connectorManager ?? null,
        connectorRole: values?.connectorRole,
        designation: values?.designation,
        empCode: values?.empCode,
        emailId: values?.email,
        gender: values?.gender,
        name: values?.name.trim(),
        phoneNumber: values?.phoneNumber,
        connectorAdditionalInfo: {
          designation: values?.designation,
          empCode: values?.empCode,
          viewPayoutScreen: values?.viewPayoutScreen ?? "false",
        },
      },
      currentStatus: null,
      kycDetails: null,
      otherDetails: null,
      profilePicture: null,
    };
    API_SERVICE.updateConnector(id, payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push("/employees");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const addEmployee = (values) => {
    const payload = buildPayload(values);
    API_SERVICE.addSubConnector(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push("/employees");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onFinishForm = (values: any) => {
    if (id) {
      editEmployee(values);
    } else {
      addEmployee(values);
    }
  };

  const getDesignationsList = () => {
    const designationsTmp = designation?.map((d: any) => {
      return { key: d.id, value: d.name };
    });
    return designationsTmp;
  };

  const getLineManagerList = () => {
    let lineManagerTmp = lineManagerList?.map((d: any) => {
      const designationData: any = designation.find((designation: any) =>
        designation.id == d.connectorDesignation
      )
      let name = d.fullName;
      if((designationData?.name?.length ?? 0) > 0) {
        name = `${d.fullName} (${designationData?.name ?? ""})`;  
      }
      return { key: d.id, value: name };
    });
    return lineManagerTmp;
  };
  const customRadio: any = { '--bgColor': appColors?.appSecondaryColor ?? "" };
  return (
    <div className="content-box">
      <Title level={3}>
        <Link className="link" to={"/employees"} style={{"--color": appColors?.appPrimaryColor ?? ""} as any}>
          Employee
        </Link>{" "}
        &nbsp; &gt; &nbsp;
        {`${
          isViewScrren
            ? "View Employee"
            : isEditScreen
            ? "Edit Employee"
            : "Add New Employee"
        }`}
      </Title>

      <Form
        layout="vertical"
        autoComplete="off"
        name="leadForm"
        form={employeeForm}
        onFinish={onFinishForm}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={8} span={8}>
            <FormInput
              name="name"
              label="Full Name"
              placeholder="Enter your full name"
              isRequired
              isBorder
              rules={[
                {
                    pattern: EMPLOYEE_NAME_REGEX,
                    message: "Plesae enter valid name"
                },
            ]}
              isDisabled={isViewScrren}
            />

            <FormInput
              name={"phoneNumber"}
              prefix="+91"
              label="Mobile Number"
              isBorder
              isRequired
              placeholder="Enter your mobile number"
              isDisabled={isViewScrren}
              rules={[
                {
                  pattern: PHONE_NUMBER_REGEX,
                  message: "Please enter valid number",
                },
              ]}
            />

            <FormInput
              name="email"
              label="Email ID"
              placeholder="Enter your email ID"
              isRequired
              isBorder
              isDisabled={isViewScrren}
              rules={[{ type: "email", message: "Please enter valid email" }]}
            />

            <FormInput
              name="empCode"
              label="Employee Code"
              placeholder="Enter your employee code"
              isBorder
              isDisabled={isViewScrren}
            />

            <RadioButton
              label="Gender"
              name="gender"
              isRequired
              radioList={getEnumList("GENDER")}
              isDisabled={isViewScrren}
              style={customRadio}
              className="custom-radio profession-btns"
            />

            <FormSelect
              name={"designation"}
              label="Designation"
              options={getDesignationsList() as any}
              isBorder
              disabled={isViewScrren}
              placeholder="Select designation"
            />

            <FormSelect
              name={"connectorRole"}
              label="Role"
              placeholder="Select Role"
              isRequired
              isBorder
              disabled={isViewScrren || isConnectorEmployee}
              options={getEnumList("DEFAULT_WEB_CONNECTOR_ROLE_PERMISSION")}
            />

            <FormSelect
              name={"connectorManager"}
              label="Line Manager"
              options={getLineManagerList() as any}
              isRequired
              isBorder
              disabled={isViewScrren}
              placeholder="Select Line Manager"
            />

            {isShowAddEmployee && (
              <RadioButton
                label="Can Add Employee ?"
                name="canAddEmployee"
                style={customRadio}
                // isRequired
                isDisabled={isViewScrren}
                radioList={
                  [
                    { key: "true", value: "Yes" },
                    { key: "false", value: "No" },
                  ] as any
                }
                className="custom-radio profession-btns"
              />
            )}

            {/* {isShowPayout && (
              <RadioButton
                label="View Payout?"
                name="viewPayoutScreen"
                isRequired
                isDisabled={true}
                radioList={
                  [
                    { key: "true", value: "Yes" },
                    { key: "false", value: "No" },
                  ] as any
                }
                className="custom-radio profession-btns"
              />
            )} */}

            <Row gutter={10}>
              <Col span={12}>
                <FormButton
                  label="Cancel"
                  className="dynamic-btn-default"
                  style={
                    { "--borderColor": appColors?.appSecondaryColor ?? "" } as any
                  }
                  onclick={() => history.goBack()}
                />
              </Col>
              <Col span={12}>
                {!isViewScrren && (
                  <FormButton
                    htmlType="submit"
                    type="primary"
                    className="dynamic-btn-primary"
                    style={
                      { "--btnColor": appColors?.appPrimaryColor ?? "" } as any
                    }
                    label="Submit"
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddEmployee;
