import AXIOS from 'axios';
import Parameters, { BUSINESS_ID } from '../parameters';
import { API_CONFIG } from './config';
import parameters from '../parameters';
import { getBasicDetails, getUserDetails } from './Utility';
import dayjs from "dayjs";

type Server = "distribution" | "common" | "lender";

function getProtectedInstanceFor(server: Server = "distribution") {
    let apiUrl = Parameters.ApiUrlOne;
    if (server == "common") {
        apiUrl = Parameters.ApiUrlTwo;
    } else if (server == "lender") {
        apiUrl = Parameters.ApiUrlThree;
    }
    
    const headers: any = {};
    const user = localStorage.getItem('user');
    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
    }
    return AXIOS.create({
        //@ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl + '/',
        headers: {
            'Accept': 'application/json',
            "Content-Type": 'application/json',
            ...headers,
        }
    })
}

function getPublicInstance(firstServer = true) {
    const apiUrl = firstServer ? Parameters.ApiUrlOne : Parameters.ApiUrlTwo
    const headers = {};
    return AXIOS.create({
        // @ts-ignore 
        'accept': 'application/json',
        'baseURL': apiUrl + '/',
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
    });
}
// function getPublicFormInstance(firstServer = true) {
//     const headers = {};
//     const apiUrl = firstServer ? Parameters.ApiUrlOne : Parameters.ApiUrlTwo
//     return AXIOS.create({
//         // @ts-ignore
//         'accept': 'application/json',
//         'baseURL': apiUrl + '/free/fileupload',
//         'headers': {
//             Accept: 'application/json',
//             'Content-Type': 'multipart/form-data',
//             ...headers,
//         },
//     });
// }

function getProtectedInstanceForFinbox(headers = {}) {
    return AXIOS.create({
        // @ts-ignore
        accept: "application/json",
        // 'baseURL': apiUrl,
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            ...headers,
        },
    });
}

function getProtectedInstance(firstServer = true) {
    const user = localStorage.getItem('user');
    const headers: any = {};
    const apiUrl = firstServer ? Parameters.ApiUrlOne : Parameters.ApiUrlTwo
    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
        // headers['api_key'] = "9857b26f64df7c67e7cb75d6cc857a78";
        // headers['api_id'] = "2feccf832a8bf2f6a23520230b5c2aba";
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
    });
}


function getProtectedInstanceForDownload(firstServer = true) {
    const user = localStorage.getItem('user');
    const headers: any = {};
    const apiUrl = firstServer ? Parameters.ApiUrlOne : Parameters.ApiUrlTwo
    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
        // headers['api_key'] = "9857b26f64df7c67e7cb75d6cc857a78";
        // headers['api_id'] = "2feccf832a8bf2f6a23520230b5c2aba";
    }
    return AXIOS.create({
        // @ts-ignore
        // 'method': 'GET',
        'accept': 'application/octet-stream',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/octet-stream',
            ...headers,
        },
        responseType: 'blob',
    });
}

function getProtectedInstanceForTherdServer(thirdServer = true) {
    const user = localStorage.getItem('user');
    const headers: any = {};
    const apiUrl = thirdServer ? Parameters.ApiUrlThree : Parameters.ApiUrlTwo
    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
        // headers['api_key'] = "9857b26f64df7c67e7cb75d6cc857a78";
        // headers['api_id'] = "2feccf832a8bf2f6a23520230b5c2aba";
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
    });
}

function getProtectedInstanceForBlob(firstServer = true) {
    const user = localStorage.getItem('user');
    const headers: any = {};
    const apiUrl = firstServer ? Parameters.ApiUrlOne : Parameters.ApiUrlTwo
    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
        // headers['api_key'] = "9857b26f64df7c67e7cb75d6cc857a78";
        // headers['api_id'] = "2feccf832a8bf2f6a23520230b5c2aba";
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
        responseType: 'blob'
    });
}


function getDynamicLink() {
    const apiUrl = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCabnyCkbxNvgN3tfRl1AgaUibJhuY4hos"
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'headers': {
            'Content-Type': 'application/json',
        },
    });
}

function handleErrors(error: any) {
    let message = 'Something went wrong!!';
    if (error && error.response && error?.response?.error) {
        let parsedError: any = '';
        message = error.response.data.error;
        if (typeof error.response.data.error === 'string') {
            try {
                parsedError = JSON.parse(error.response.data.error);
                if (parsedError['errorMessage']) {
                    message = parsedError['errorMessage'];
                } else {
                    const allErrors = Array();
                    for (let i in parsedError) {
                        allErrors.push(parsedError[i]);
                    }
                    message = allErrors.join(', ');
                }
            } catch (e) {
            }
        }

    }
    else if (error?.response?.data?.payload?.errorMessage) {
        message = error.response.data.payload.errorMessage;
        let arrMessage = message.split(" {")
        if ((arrMessage?.length ?? 0) <= 1) {
            arrMessage = message.split("400{");
        }
        if ((arrMessage?.length ?? 0) <= 1) {
            arrMessage = message.split("403{");
        }
        if ((arrMessage?.length ?? 0) > 1) {
            const objMessage = JSON.parse("{" + arrMessage[1])
            if (objMessage?.errors) {
                if ((objMessage?.errors?.length ?? 0) > 0) {
                    let msg = '';
                    objMessage?.errors?.filter((item: any) => {
                        msg = item.errorCodeDescription + ',' + msg;
                    })
                    message = msg ?? msg
                }
            }
            message = objMessage?.message ?? message
        }
    }
    else if (error && error.response && error.response.data && error.response?.data?.message) {
        return message = error.response.data.message;
    }
    else if (error && error.response && error.response.data && error?.response?.data?.errors) {
        let e = Array();
        for (let i in error.response?.data?.errors) {
            e.push(`${i}: ${error.response.data.errors[i]}`);
        }
        message = e.join(',');
    } else if (typeof error === 'string') {
        message = error;
    }
    return message;
}

const get_BUSINESS_ID = () => {
    const user = localStorage.getItem('user');
    if (user) {
        const businessId = JSON.parse(user as string)?.businessId
        return businessId ?? BUSINESS_ID
    }
    return BUSINESS_ID
}

const get_DSA_BUSINESS_ID = () => {
    const user = localStorage.getItem('user');
    if (user) {
        const dsaBusinessId = JSON.parse(user as string)?.dsaBusinessId
        return dsaBusinessId ?? getBasicDetails()?.dsaBusinessId
    }
    return getBasicDetails()?.dsaBusinessId ?? BUSINESS_ID
}

async function generatDunamicLink(connectorId, salesID, dsaBusinessId) {
    const instance = getDynamicLink();
    const apiUrl = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCabnyCkbxNvgN3tfRl1AgaUibJhuY4hos"
    const payload = {
        "dynamicLinkInfo": {
            "domainUriPrefix": "https://oneflo.page.link",
            "link": `${parameters.AppUrl}sign-up?connID=${connectorId}&salesID=${salesID}&dsaBusinessID=${dsaBusinessId}&referralSource=CONNECTOR_APP`,
            "androidInfo": {
                "androidPackageName": "com.dc.conn.oneflo.android"
            },
            "iosInfo": {
                "iosBundleId": "com.dc.conn.Oneflo.iOS"
            }
        }
    }
    return await instance.post(apiUrl, payload);
}

async function login(payload: object) {
    const instance = getPublicInstance(false);
    return await instance.post(API_CONFIG.login, payload);
}

async function checkCaptchaRequired(payload: object) {
    const instance = getPublicInstance(false);
    return await instance.post(API_CONFIG.checkCaptchaRequired, payload);
}

async function getOTPmobile(mobile: string) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.getOTP + mobile);
}

async function verifyOtp(payload: any) {
    const instance = getProtectedInstance(false);
    return await instance.post(API_CONFIG.verifyOtp, payload);
}

async function checkEmail(emailId: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.checkEmail + emailId);
}

async function checkValidateConnector(payload: object) {
    const instance = getPublicInstance(false);
    return await instance.post("free/check-validate-connector", payload);
}

async function checkSlateUser(connectorId: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(`/api/free/check/slate/user/${connectorId}`);
}

async function checkStateUser(connectorId: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.checkStateUser + connectorId);
}

async function getTenantDocumentDetails() {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.tenantDocDetails);
}


async function submitReferralCode(payload: any) {
    const instance = getProtectedInstance(false);
    return await instance.post(API_CONFIG.submitReferralCode, payload);
}

async function getTypes() {
    const instance = getProtectedInstanceForTherdServer(false);
    const user = localStorage.getItem('user');
    const loggedInFrom = JSON.parse(user as string).loggedInFrom;
    return await instance.get(API_CONFIG.enumValues + (loggedInFrom ?? "web"));
}

async function getTypesFree() {
    const instance = getPublicInstance(false);
    return await instance.get(API_CONFIG.enumValuesFree);
}

async function searchCustomers(text?: string) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.searchCustomers + (text ?? ""));
}

async function getConnectorById(id: number) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.connectorById + "/" + id);
}
async function getSubConnectors(connectorId?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.subConnectorList + connectorId);
}

async function checkPanDetails(params?: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.panDetails, { params });
}

async function checkPanDetailsForProfile(params?: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.panDetailsForProfile, { params });
}

async function checkPincode(params?: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.pinDetails, { params });
}
async function addLead(params?: any) {
    let url = params.applicationType !== 'INDIVIDUAL' ? API_CONFIG.addLeadBusiness : API_CONFIG.addLeadIndividual;
    const instance = getProtectedInstance();
    return await instance.post(url, params);
}

async function editLead(caseId: string, payload?: any) {
    let url = `${API_CONFIG.editLead}${caseId}`;
    const instance = getProtectedInstance();
    return await instance.post(url, payload);
}

async function getLeads(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.leadList, { params });
}

async function getLeadsByFilter(page, pageSize,screenOrigin:any, params?: any) {
    const instance = getProtectedInstance();
    const user = localStorage.getItem('user');
    const loggedInFrom = JSON.parse(user as string).loggedInFrom;
    return await instance.post(`${API_CONFIG.leadListbyFilter}?loggedInFrom=${loggedInFrom ?? "web"}&pageNo=${page}&pageSize=${pageSize}&screenOrigin=${screenOrigin}`, params);
}

async function getLeadsByFilterPostCase(lenderStatus: any, pageNo, pageSize,screenOrigin:any, params?: any) {
    const instance = getProtectedInstanceForTherdServer();
    const user = localStorage.getItem('user');
    const loggedInFrom = JSON.parse(user as string).loggedInFrom;
    return await instance.post('dsa/lender/' + lenderStatus + `/filter/connector/cases/list?loggedInFrom=${loggedInFrom ?? "web"}&pageNo=` + pageNo + '&pageSize=' + pageSize + '&screenOrigin=' + screenOrigin  , params);
}

async function getHeirarchyUsers(userId) {
    const instance = getProtectedInstance();
    return await instance.get(`${API_CONFIG.heirarchyUsers}${userId}`);
}

async function getLeadsById(id: number) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.leadListById + id + `?workFlowType=ASSISTED`);
}

async function getConnectorEditStatus(caseId:any) {
    const instance = getProtectedInstance();
    const url = `case/${caseId}/check/can-connector-edit-form`
    return await instance.get(url);
    
}
async function updateConnector(id: any, params: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.updateConnector + "/" + id, params);
}

async function fileUpload(params: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(API_CONFIG.fileUploadfree, params);
}

async function getDocumentHierarchyList(params: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.documentHierarchyListfree, { params });
}

async function getCaseDetailsByShareLinkId(shareLinkId: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.caseDetailByShareLinkId + shareLinkId);
}

async function getDocument(businessDocumentId: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.getDocument + businessDocumentId);
}

async function addDocument(params: any, documentSetId: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(API_CONFIG.addDocumentfree + documentSetId, params, {});
}

async function getDocumentList(documentSetId: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.documentListfree + documentSetId + '/list');
}

async function deleteDocument(businessId: any, documentSetId: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(API_CONFIG.deleteDoc + businessId + '/' + documentSetId);
}

async function getBasicOfferByCaseId(caseId: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.basicOffersByCaseId + caseId);
}

async function getSubConnector(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get("list/sub-connector", { params });
}

async function addSubConnector(params?: any) {
    const instance = getProtectedInstance();
    return await instance.post("add/sub-connector", params);
}

async function getDesignation(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.designation + '/' + get_BUSINESS_ID(), { params });
}

async function getActiveDesignation() {
    const instance = getProtectedInstance();
    return await instance.get("list/connector/active-designation");
}

async function getLineManager() {
    const instance = getProtectedInstance();
    return await instance.get("list/hierarchy/connector/user-details");
}

async function getOffers(params?: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(
        API_CONFIG.listOffer + '/' + get_DSA_BUSINESS_ID() + '/OFFER?status=Active',
        { params },
    );
}

async function getVerifyUser(userId?: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(API_CONFIG.verify + '/' + `${userId}` + '/approved');
}

async function getNotices(params?: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(
        API_CONFIG.listOffer + '/' + get_DSA_BUSINESS_ID() + '/NOTICE?status=Active',
        { params },
    );
}

async function addDesignation(payload: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.designation + '/' + get_BUSINESS_ID(), { ...payload });
}
async function editDesignation(payload: any) {
    const instance = getProtectedInstance();
    return await instance.put(API_CONFIG.designation + '/' + get_BUSINESS_ID(), { ...payload });
}

async function getBusinessid(id: number) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.get(API_CONFIG.getBusinessid + get_BUSINESS_ID() + '/' + id);
}

async function updateOffers(params: any, id: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    return await instance.post(API_CONFIG.updateOffers + get_BUSINESS_ID() + '/' + id, params);
}

async function patchCase(caseId: any, payload: any) {
    const instance = getProtectedInstance();
    return await instance.post(`patch/case/${caseId}`, { ...payload });
}

async function closeCase(caseId: any, payload: any) {
    const instance = getProtectedInstance();
    return await instance.post(`${API_CONFIG.updateCase}${caseId}/status`, { ...payload });
}

async function getUser(id: number) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.user + get_BUSINESS_ID() + '/' + id);
}

async function statusChange(id: any, status: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.statusChange + id + '/' + status);
}

async function downloadDocument(businessId: any, documentId: any, params?: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    const url = `document-upload/get/${documentId}`
    return await instance.get(url, {params});
}

async function termsAndConditions() {
    const instance = getProtectedInstance(false);
    return await instance.get(
        API_CONFIG.helpTermsAndConditions + 'BU6098926543/TERMS_AND_CONDITIONS'
    );
}

async function privacyPolicy() {
    const instance = getProtectedInstance(false);
    return await instance.get(
        API_CONFIG.helpPrivacyPolicy + 'BU6098926543/PRIVACY_POLICY'
    );
}

async function faq() {
    const instance = getProtectedInstance(false);
    return await instance.get(
        API_CONFIG.helpFaq + 'BU6098926543/FAQ'
    );
}

async function getUsersPermission(userId: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.userPermission + userId + '/permissions');
}

async function getListHierarchy(params) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.listHierarchy, { params });
}

async function updatePassword(payload) {
    const instance = getProtectedInstance(false);
    return await instance.post(API_CONFIG.updatePassword, payload);
}

async function checkReferralCode(referralCode: any) {
    const instance = getPublicInstance(false);
    return await instance.get(API_CONFIG.referralCode + referralCode);
}

async function userSignUp(referralSource, dsaBusinessId, payload, type: any) {
    const instance = getProtectedInstance(false);
    let url = API_CONFIG.userSignUp + `?connectorBusinessType=${type}`
    if ((referralSource?.length ?? 0) > 0) {
        url = url + `&referralSource=${referralSource}`
    }
    if ((dsaBusinessId?.length ?? 0) > 0) {
        url = url + `&dsaBusinessId=${dsaBusinessId}`
    }
    return await instance.post(url, payload);
}

async function updateBusiness(connectorId: string, payload) {
    const instance = getProtectedInstance(false);
    return await instance.put(`free/user/update/business/${connectorId}`, payload);
}

async function updateAuthSignatory(connectorId: string, payload) {
    const instance = getProtectedInstance(false);
    return await instance.put(`free/user/update/auth-signatory/${connectorId}`, payload);
}

async function resetPassword(payload) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.resetPassword, payload);
}

async function generateReportByReportType(startDate: string, endDate: string, reportType) {
    const instance = getProtectedInstanceForBlob();
    const url = `${API_CONFIG.generateReportByReportType}startDate=${startDate}&endDate=${endDate}&reportType=${reportType}`
    return await instance.get(url);
}

async function getPayoutsOfConnectorsDisbursed(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.payoutsOfConnectorDisbursedList, { params });
}

async function getPayoutOfConnectorsPayout(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.payoutsOfConnectorPayoutList, { params })
}

async function createPayoutReq(params: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.createPayoutReq, params)
}

async function approveRetryPayouts(isApprove: boolean, payload: object) {
    const instance = getProtectedInstance();
    return await instance.post((API_CONFIG.approvePayoutInfo + (isApprove ? "approve" : "retry")), payload);
}

async function bulkUploadStart(params: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.bulkUploadStart, params);
}

async function bulkUploadMap(uploadId: string, entityType: string) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.bulkUploadMap + uploadId + "/extract/headers?entityType=" + entityType + "");
}

async function bulkUploadExecute(uploadId: string, entityType: string) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.bulkUploadExecute + uploadId + "/execute?entityType=" + entityType + "");
}

async function bulkUploadDelete(uploadId: any) {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.bulkUploadDelete + uploadId + "/update/delete-status");
}

async function getBulkUploadList(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.bulkUploadList, { params });
}

async function getDownloadFormate(entityType: string) {
    const instance = getProtectedInstanceForDownload();
    return await instance.get(API_CONFIG.downloadFormate + "?entityType=" + entityType + "");
}

async function getShareLinkId(caseId: any) {
    const instance = getProtectedInstance(true);
    return await instance.get(`${API_CONFIG.getShareLinkId + caseId}`);
}

async function getUnreadNotificationCnt() {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.unreadNotificationCnt);
}

async function getNotificationList(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.notificationList, { params });
}

async function updateNotificationStatus(notificationId) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.updateNotificationStatus + '/' + `${notificationId}`);
}

async function clearAllNotifications() {
    const instance = getProtectedInstance();
    return await instance.post(API_CONFIG.clearAllNotifications);
}

async function getUsersByDepartment(params?: any) {
    const instance = getProtectedInstance(true);
    return await instance.get(`${API_CONFIG.usersByDepartment}`, { params });
}

async function getConnector(params?: any) {
    const instance = getProtectedInstance();
    return await instance.get(API_CONFIG.connectorLists, { params });
}

async function getUsersWithRole(params?: any) {
    const instance = getProtectedInstance(true);
    return await instance.get(`${API_CONFIG.usersWithRole}`, { params });
}

async function updateLoanProductandStartFlowable(params: any) {
    const instance = getProtectedInstance(true);
    let url = `check-version/update-product?caseId=${params?.caseId}&loanProductId=${params?.loanProductId}`;
    if(params?.insuranceCaseOnline != undefined) {
        url = url + `&insuranceCaseOnline=${params?.insuranceCaseOnline ?? null}`;
    }
    return await instance.post(url)
}

async function addCreateUserDetails(payload?: any) {
    const instance = getProtectedInstance(true);
    const url = `/v2/add/case`;
    return await instance.post(url, payload);
}

async function getLeadAdditional(pan: string) {
    let url = API_CONFIG.getLeadAdditional + pan;
    const instance = getProtectedInstance();
    return await instance.get(url);
}

async function getFinboxURL(userId: String) {
    const date = dayjs().subtract(1, "year").startOf("month");
    const toDate = dayjs().subtract(1, "month").endOf("month");
    const params = {
        link_id: userId,
        api_key: "sC5eBP7oZe8x0wF7fbqC0bY0EAQOjPRIWhztZIli",
        from_date: date.format("DD/MM/YYYY"),
        to_date: toDate.format("DD/MM/YYYY"),
        logo_url:
            "https://oneflo.in/static/media/dashboard-logo.4ffa136039642bb9b45b.jpeg",
    };
    // to_date
    const instance = getProtectedInstanceForFinbox();
    return await instance.post(
        "https://portal.finbox.in/bank-connect/v1/session/",
        params
    );
}

function getPubslicInstanceForTherdServer(thirdServer = true) {
    // const user = localStorage.getItem('user');
    const headers: any = {};
    const apiUrl = thirdServer ? Parameters.ApiUrlThree : Parameters.ApiUrlTwo
    // if (user) {
    // headers['api_key'] = JSON.parse(user as string).apikey;
    // headers['api_id'] = JSON.parse(user as string).apiId;
    headers['api_key'] = "6743e0681860ce20c8d98b7fcd76bd4b";
    headers['api_id'] = "16e40d1ae463ca1fb138da75c41f3856";
    //         api_id:16e40d1ae463ca1fb138da75c41f3856
    // api_key:6743e0681860ce20c8d98b7fcd76bd4b
    // }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
    });
}

async function updateCaseStatus(caseId: string, payload) {
    const instance = getProtectedInstance();
    const url = `update/case/${caseId}/status`;
    return await instance.post(url, payload);
}

async function listDecisioning(caseId: string, checkAvailability: boolean, decisioningMasterId?: string, journeyId?:string) {
    const instance = getProtectedInstance(false);
    let url = `decision/data/list?caseId=${caseId}&checkAvailability=${checkAvailability}`;
    if ((decisioningMasterId?.length ?? 0) > 0) {
        url = url + `&decisioningMasterId=${decisioningMasterId}`;
    }
    if((journeyId?.length ?? 0) > 0){
        url = url + `&journeyIds=${journeyId}`;
    }
    return await instance.get(url);
}

async function productGetNextTask(payload: any) {
    const instance = getProtectedInstance();
    const url = 'product-workflow/get-task';
    return await instance.post(url, payload);
}

async function fetchProductWorkflowById(id:string) {
    const instance = getProtectedInstance(false);
    const url = `v1/product-workflow/master-product/${id}`;
    return await instance.get(url);
}

async function productSubmitTask(payload: any) {
    const instance = getProtectedInstance(true);
    const url = 'product-workflow/submit-task';
    return await instance.post(url, payload);
}

async function getBureauScore(pan: string) {
    const instance = getProtectedInstance();
    return await instance.get(`/bureau/bureauScore/status?panId=${pan}`)
}

async function genrateBureauScore(payload: any) {
    const instance = getProtectedInstance();
    return await instance.post('bureau/generate/bureauScore', payload)
}

async function updateCase(caseId: any, caseStatus: any, payload: any, productId: any) {
    const instance = getProtectedInstanceForTherdServer();
    return await instance.post(`dsa/lender/case/${caseId}/status/${caseStatus}/lender-details/update?loanProductId=${productId}`, { ...payload });
    // return await instance.post(`dsa/lender/case/${caseId}/status/${caseStatus}/lender-details/update`, { ...payload });
}

async function getLenderAdditonalField(lenderId: string, caseId: string, productType: string) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/${lenderId}/product/fields?productType=${productType}`
    // const url = `dsa/lender/${lenderId}/fields?caseId=${caseId}&loanProductId=${productType}`
    //  const url = `dsa/lender/${lenderId}/${productType}/fields?caseId=${caseId}`
    const url = `dsa/lender/start-lender-processing/${lenderId}/case/${caseId}`
    // const url = `dsa/lender/${lenderId}/fields`
    return await instance.get(url);
}

async function listDocumentV2(caseId:string) {
    const instance = getProtectedInstance();
    const url = `list/document/case/${caseId}`;
    return await instance.get(url);
}

async function postLenderDocumentUploadDetails(lenderId: string, caseId: string) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderId}/case/${caseId}/lender_document_upload_details`
    return await instance.post(url);
}

async function updateBankDetailForLendingKart(documentBusinessID: any, payload: any) {
    const instance = getProtectedInstanceForTherdServer(false);
    const url = `documents/${documentBusinessID}/update`
    return await instance.post(url, payload);
}

async function getLenderDocumentUploadDetails(lenderId: string, caseId: string) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderId}/case/${caseId}/lender_document_upload_details`
    return await instance.get(url);
}

async function updateDocumentToLender(lenderId: any, loanProductId: any, caseId: any, params: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderId}/${loanProductId}/case/${caseId}/submit_documents_to_lender`
    return await instance.post(url, params);
}

async function deleteDocReference(caseId: any, documentBusinessID: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/all/case/${caseId}/remove_document_references/${documentBusinessID}`
    return await instance.get(url);
}

async function getLenderDocumentlist(lenderId: string, caseId: string) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderId}/document/list?caseId=${caseId}`
    return await instance.get(url);
}

async function getReplaceDocumentList(documentSetId: any) {
    const instance = getProtectedInstance(false);
    return await instance.get(API_CONFIG.documentList + documentSetId + '/replace/list');
}

async function renameDocument(docId: string, payload: any) {
    const instance = getProtectedInstance(false);
    const url = `documents/${docId}/update`
    return await instance.post(url, payload);
}

async function checkExpireyLink(uniqueId: any) {
    const instance = getProtectedInstance();
    return await instance.post('bureau/free/link/expiry', uniqueId)
}

async function updateConsent(uniqueId: any) {
    const instance = getProtectedInstance();
    return await instance.put('bureau/free/updateConsent', uniqueId)
}

async function getConsentInfo(payload: any) {
    const instance = getProtectedInstance();
    return await instance.post('bureau/getConsent', payload)
}

async function panVerify(data?: any) {
    const instance = getProtectedInstance(false);
    return await instance.post("verify/pan", data)
}

async function getOtpVerificationDetails(lenderId: any, caseId: any) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/get-next-task/${lenderId}/case/${caseId}`
    const url = `dsa/lender/icici/get/otp-verification/applicant?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.get(url);
}

async function updateVerifyOtpICICI(lenderId: any, caseId: any, payload) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/icici/verify/otp?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.post(url, payload);
}

async function updateRetrigerVerifyOtpICICI(lenderId: any, caseId: any, payload) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/icici/re-trigger/otp?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.post(url, payload);
}

async function updateRetriveOfferAcceptance(lenderId: any, caseId: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/icici/get/latest/loan-eligibility?caseId=${caseId}&lenderId=${lenderId}`;
    // const url = `dsa/lender/icici/re-trigger/otp?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.get(url);
}

async function getUpdatedICICIEmiCal(lenderId: any, caseId: any, payload) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/icici/get/latest/loan-eligibility?caseId=${caseId}&lenderId=${lenderId}`;
    const url = `dsa/lender/icici/fetch-loan-eligibility?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.post(url, payload);
}

async function getNextTask(caseId: any, lenderId: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/get-next-task/${lenderId}/case/${caseId}`
    return await instance.get(url);
}

async function submitTask(lenderId: string, caseId: string, productType: string, payload) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/start-lender-processing/${lenderId}/case/${caseId}`
    const url = `dsa/lender/submit-task/${lenderId}/case/${caseId}`
    return await instance.post(url, payload);
}

async function moneyWideCheckConsent(caseId: any) {
    const instance = getPubslicInstanceForTherdServer(true);

    const url = `/dsa/lender/moneywide/consent/${caseId}`
    return await instance.get(url);
}

async function moneyWideTriggerConsentSMS(caseId: any) {
    const instance = getProtectedInstanceForTherdServer(true);

    const url = `dsa/lender/moneywide/tnc/${caseId}/retrigger-sms`;
    return await instance.post(url, {});
}

async function updateLenderAdditonalFieldForBajaj(lenderId: string, caseId: string, productType: string, payload) {
    const instance = getProtectedInstanceForTherdServer();
    const user = localStorage.getItem('user');
    let userId: any = "";
    if (user) {
        userId = JSON.parse(user as string).userDetails.userId;
    }
    const url = `dsa/lender/submit-to-lender/${lenderId}/case/${caseId}/${userId}`
    // const url = `dsa/lender/${lenderId}/${productType}/case/${caseId}/${userId}`
    return await instance.post(url, payload);
}

async function updateLenderAdditonalFieldForBajajSaleUser(lenderId: string, caseId: string, productType: string, payload) {
    const instance = getProtectedInstanceForTherdServer();
    const user = localStorage.getItem('user');
    let userId: any = "";
    if (user) {
        userId = JSON.parse(user as string).userDetails.userId;
    }
    const url = `dsa/lender/submit-to-lender-bajaj/${lenderId}/case/${caseId}/${userId}`
    // const url = `dsa/lender/${lenderId}/${productType}/case/${caseId}/${userId}`
    return await instance.post(url, payload);
}

async function updateLenderAdditonalField(lenderId: string, caseId: string, productType: string, payload) {
    const instance = getProtectedInstanceForTherdServer();
    const user = localStorage.getItem('user');
    let userId: any = "";
    if (user) {
        userId = JSON.parse(user as string).userDetails.userId;
    }
    const url = `dsa/lender/${lenderId}/${productType}/case/${caseId}/${userId}`
    return await instance.post(url, payload);
}

async function sendCashESMSLink(lenderID: any, caseId: any) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/${lenderInfoId}/product/${loanProductID}/case/${caseId}/${lenderID}/redirect_url`
    const url = `dsa/lender/cashe/${caseId}/lender/${lenderID}/retrigger-sms`
    // api/dsa/lender/cashe/DC1025619905/lender/BU1400904544/retrigger-sms
    return await instance.get(url);
}

async function getCredilioURL(lenderInfoId: any, loanProductID: any, lenderID: any, caseId: any) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderInfoId}/product/${loanProductID}/case/${caseId}/${lenderID}/redirect_url`
    return await instance.get(url);
}

async function getSanctionLetterForICICI(lenderId: any, caseId: any) {
    const instance = getProtectedInstanceForTherdServer();
    // const url = `dsa/lender/icici/get/latest/loan-eligibility?caseId=${caseId}&lenderId=${lenderId}`;
    const url = `dsa/lender/icici/download/letter?caseId=${caseId}&lenderId=${lenderId}`
    return await instance.get(url);
}

async function getLenderRequestPayload(caseId: string, lenderId: string) {
    const instance = getProtectedInstance();
    return await instance.get(`case/${caseId}/lender/${lenderId}/request-payload`);
  }
  async function sendWhatsAppMessage(caseID:any){
    const instance = getProtectedInstance();
    const url = `/communication/whatsapp/document_collection/case/${caseID}`;
    return await instance.post(url); 
}
async function getWhatsAppDocumentValidate(caseID:any) {
    const instance = getProtectedInstance();
    return await instance.get(`communication/whatsapp/document_collection/case/${caseID}/validate`);
  }
  async function updateDocumentBankDetail(documentBusinessID:string, payload: any) {
    const instance = getProtectedInstance(false);
    const url = `document-upload/${documentBusinessID}/update/v2`;
    return await instance.put(url, payload);
}

async function getDocumentBankList(masterKey:string) {
    const instance = getProtectedInstance();
    const url = `dc-master-data/list/${masterKey}`;
    return await instance.get(url);
}

// getProtectedInstanceForTherdServer
async function getDocumentBankListFoLender(masterKey:string, lenderShortCode: string) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/${lenderShortCode}/product/ALL/master/${masterKey}/getLenderMasterData?pageSize=100&limit=100&skip=0'`
    // const url = `dc-master-data/list/${masterKey}`;
    return await instance.get(url);
}

async function getLenderShortCode(lenderInfoID: string) {
    const instance = getProtectedInstanceForTherdServer();
    const url = `dsa/lender/get-lender-short-code/${lenderInfoID}`
    // const url = `dc-master-data/list/${masterKey}`;
    return await instance.get(url);
}

async function purgeAllCaseDecisioningData(caseId:string) {
    const instance = getProtectedInstance(false);
    const url = `decision/data/purge/${caseId}`;
    return await instance.put(url);
}

const API_SERVICE = {
    generatDunamicLink,
    checkStateUser,
    submitReferralCode,
    termsAndConditions,
    privacyPolicy,
    faq,
    statusChange,
    getUser,
    getBusinessid,
    updateOffers,
    checkReferralCode,
    login,
    getOTPmobile,
    checkEmail,
    checkValidateConnector,
    checkSlateUser,
    verifyOtp,
    getTenantDocumentDetails,
    getLeads,
    getListHierarchy,
    getLeadsByFilter,
    getHeirarchyUsers,
    getTypes,
    getTypesFree,
    getConnectorById,
    getSubConnectors,
    checkPanDetails,
    checkPanDetailsForProfile,
    checkPincode,
    addLead,
    editLead,
    updateConnector,
    getLeadsById,
    fileUpload,
    getDocumentList,
    deleteDocument,
    getBasicOfferByCaseId,
    getDesignation,
    getActiveDesignation,
    getSubConnector,
    addSubConnector,
    getLineManager,
    patchCase,
    closeCase,
    getOffers,
    getVerifyUser,
    getNotices,
    addDesignation,
    editDesignation,
    handleErrors,
    downloadDocument,
    getUsersPermission,
    updatePassword,
    userSignUp,
    resetPassword,
    searchCustomers,
    getPayoutsOfConnectorsDisbursed,
    getPayoutOfConnectorsPayout,
    createPayoutReq,
    approveRetryPayouts,
    getDocument,
    addDocument,
    getDocumentHierarchyList,
    bulkUploadStart,
    bulkUploadMap,
    bulkUploadExecute,
    bulkUploadDelete,
    getBulkUploadList,
    getDownloadFormate,
    getLeadsByFilterPostCase,
    generateReportByReportType,
    getCaseDetailsByShareLinkId,
    getShareLinkId,
    getUnreadNotificationCnt,
    getNotificationList,
    updateNotificationStatus,
    clearAllNotifications,
    updateBusiness,
    updateAuthSignatory,
    getUsersByDepartment,
    getConnector,
    getUsersWithRole,
    updateLoanProductandStartFlowable,
    addCreateUserDetails,
    getLeadAdditional,
    getFinboxURL,
    updateCaseStatus,
    listDecisioning,
    productGetNextTask,
    fetchProductWorkflowById,
    productSubmitTask,
    getBureauScore,
    genrateBureauScore,
    updateCase,
    getLenderAdditonalField,
    listDocumentV2,
    postLenderDocumentUploadDetails,
    updateBankDetailForLendingKart,
    getLenderDocumentUploadDetails,
    updateDocumentToLender,
    deleteDocReference,
    getLenderDocumentlist,
    getReplaceDocumentList,
    renameDocument,
    checkExpireyLink,
    updateConsent,
    getConsentInfo,
    panVerify,
    getOtpVerificationDetails,
    updateVerifyOtpICICI,
    updateRetrigerVerifyOtpICICI,
    updateRetriveOfferAcceptance,
    getUpdatedICICIEmiCal,
    getNextTask,
    submitTask,
    moneyWideCheckConsent,
    moneyWideTriggerConsentSMS,
    updateLenderAdditonalFieldForBajaj,
    updateLenderAdditonalFieldForBajajSaleUser,
    updateLenderAdditonalField,
    sendCashESMSLink,
    getCredilioURL,
    getSanctionLetterForICICI,
    getLenderRequestPayload,
    sendWhatsAppMessage,
    getWhatsAppDocumentValidate,
    updateDocumentBankDetail,
    getDocumentBankList,
    getDocumentBankListFoLender,
    getLenderShortCode,
    purgeAllCaseDecisioningData,
    getConnectorEditStatus,
    checkCaptchaRequired
};
export default API_SERVICE;